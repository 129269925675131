import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Section from "../../components/Section"
import Col from 'react-bootstrap/Col';
import { getFeaturedItem, getFeaturedItems } from "../../services/utility"
import Teaser from "../../components/Teaser";
import Feature from "../../components/Feature";
import Button from "react-bootstrap/Button"
import Headline from "../../components/Headline"

export default class Resources extends React.Component {
  render() {
    let top = getFeaturedItem(this.props.data.top);
    let featured = getFeaturedItems(this.props.data.featured);
    let topics = getFeaturedItems(this.props.data.topics);
    return ( 
      <Layout>
        <Seo title="Resources" />
        {top && <Section className="primary" useContainer={false}>
          <Feature content={top} centerVertically={true} />
        </Section> } 
        {featured && <Section className="secondary" useCol={false}>
          <Col xs={12}><Headline>Featured</Headline></Col>
          {featured.map((resource) => {
            return (
              <Col md={4} key={"resource-" + resource.slug}>
                <Teaser content={resource} />
              </Col>
            );
          })}
          <Col xs={12} className="text-center"><Button href="/resources/all" variant="primary" className="px-4 font-weight-bold">SHOW ALL</Button></Col>          
        </Section> }
        {topics && <Section className="white" useCol={false}>
          <Col xs={12}><Headline center={true}>Hot Topics</Headline></Col>
          {topics.map((topic) => {
            return (
              <Col md={3} key={"topic-" + topic.slug}>
                {topic.image && <Link to={`/resources/topic-${topic.slug}`}><GatsbyImage image={getImage(topic.image)} alt={topic.name} /></Link>}
                {!topic.image && <h1><Link to={`/resources/topic-${topic.slug}`}>NO IMAGE</Link></h1>}
                <h3 className="pt-2"><Link to={`/resources/topic-${topic.slug}`}>{topic.name}</Link></h3>
              </Col>
            );
          })}
        </Section> }
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    top: allContentfulFeatured(filter: {siteSection: {eq: "Resources - Top"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }
    featured: allContentfulFeatured(filter: {siteSection: {eq: "Resources - Must See"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }    
    topics: allContentfulFeatured(filter: {siteSection: {eq: "Resources - Topics"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Topic
          }
        }
      }
    }
  }
`